import Box from '@mui/material/Box';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import LinearProgress from '@mui/material/LinearProgress';
import { blueIOS } from '../../res/colors';


type HorizontalLoadingTypes = {
  style: object;
  color: string;
};

export default function HorizontalLoading(props: HorizontalLoadingTypes) {
  return (
    <ThemeProvider theme={theme}>
      <Box sx={{ width: '100%' }} style={props.style}>
        <LinearProgress />
      </Box>
    </ThemeProvider>
  );
}

const theme = createTheme({
  palette: {
    primary: {
      main: blueIOS,
    }
  },
});