export const teal200 = '#03DAC5';
export const teal700 = '#018786';
export const black = '#000000';
export const white = '#FFFFFF';
export const white60p = '#99FFFFFF';
export const blueIOS = '#007AFF';
export const blueIOS05p = '#E6F2FF';
export const greenIOS = '#10903D';
export const blueGMaps = '#A8C7FA';
export const redIOS = '#FD3C31';
export const redIOS2 = '#FF3934';
export const grayBgIOS = '#F2F1F6';
export const grayIOSswitch = '#E9E9E9';
export const grayIOS = '#232323';
export const grayGoogle = '#2D2E30';
export const grayGMaps = '#202124';
export const gray100 = '#F3F3F7';
export const gray400 = '#BDBDBD';
export const gray600 = '#757575';
export const gray900 = '#212121';
export const pint100 = '#F8BBD0';
export const greenSO = '#5A9E70';
export const redSO = '#C4514F';
export const orange = '#EF9C00';