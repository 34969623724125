import { Card, Divider, Typography } from "@mui/material";
import { black } from "../../res/colors";
import { OrderTotalsType, OrderType } from "../../types/types";
import { formatAmount } from "../../utils/utils";

export type BillDetailsTypes = {
  totals: OrderTotalsType;
  currency: string;
};

const BillDetails = (billDetails: BillDetailsTypes) => {
  return (
    <Card
      id="bill-details"
      variant="outlined"
      style={{
        margin: 16, paddingLeft: 16, paddingTop: 8, paddingBottom: 8, paddingRight: 16,
        border: "none"
      }}>
      <Typography
        style={{ color: black, fontWeight: 500 }}
        variant="subtitle1"
      >
        Bill Details
      </Typography>
      <Divider style={{ marginTop: 8 }} />
      <div id="bill-items" style={{ display: 'flex', flexDirection: 'column', marginTop: 8 }}>
        <div id="bill-item-total" style={{ display: 'flex', flexDirection: 'row' }}>
          <Typography
            style={{ color: black, flex: 1 }}
            variant="subtitle1"
          >
            Items Total
          </Typography>
          <Typography
            style={{ color: black }}
            variant="subtitle1"
          >
            {formatAmount(
              billDetails.totals.itemTotal,
              navigator.language,
              billDetails.currency,
              true
            )}
          </Typography>
        </div>
        <Typography
            style={{ color: black, flex: 1 }}
            variant="subtitle1"
          >
            Taxes
          </Typography>
        <div id="bill-tax-total" style={{ display: 'flex', flexDirection: 'column', marginLeft: 8 }}>
          {billDetails.totals.taxes.map(tax => (
            <div id={tax.taxId} style={{ display: 'flex' }}>
              <Typography
                style={{ color: black, flex: 1 }}
                variant="subtitle1"
              >
                {tax.taxName}
              </Typography>
              <Typography
                style={{ color: black }}
                variant="subtitle1"
              >
                {formatAmount(
                  tax.taxTotal,
                  navigator.language,
                  billDetails.currency,
                  true
                )}
              </Typography>
            </div>
          ))}
        </div>
      </div>
      <Divider style={{ marginTop: 8 }} />
      <div id="bill-total" style={{ display: 'flex', flexDirection: 'row', marginTop: 8 }}>
        <Typography
          style={{ color: black, fontWeight: 500, flex: 1 }}
          variant="subtitle1"
        >
          Total
        </Typography>
        <Typography
          style={{ color: black, fontWeight: 500 }}
          variant="subtitle1"
        >
          {formatAmount(
            billDetails.totals.grandTotal,
            navigator.language,
            billDetails.currency,
            true
          )}
        </Typography>
      </div>
    </Card>
  );
};

export default BillDetails;
