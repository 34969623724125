import { Typography } from "@mui/material";
import FoodType from "../common/FoodType";
import { blueIOS, gray100, greenIOS } from "../../res/colors";
import { formatAmount, getTotalItemPrice } from "../../utils/utils";
import { OrderedItemType } from "../../types/types";
import { getAddOnSummaryItem } from "../ordering/OrderSummaryItem";
import { OrderStatusEnum } from "../../utils/constants";


type OrderItemProps = {
  orderedItem: OrderedItemType;
  currency: string;
  orderStatus: OrderStatusEnum;
};

const OrderItem = ({ orderedItem, currency, orderStatus }: OrderItemProps) => {
  var { foodData, addOns, quantity, processed } = orderedItem;
  var itemName = foodData.name
  if (addOns?.length && addOns?.length > 0) {
    itemName = `${foodData.name} (${formatAmount(foodData.price, navigator.language, currency)})`
  }

  const addOnOptions = foodData.addOns?.flatMap(item => item.addOnOptions);

  return (
    <div style={{ display: 'flex', flexDirection: 'column', marginTop: 12 }}>
      {(orderStatus == OrderStatusEnum.PARTIALLY_PROCESSED) ? (
        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
          <div style={{ background: (processed ? greenIOS : blueIOS), width: 4, height: 16, borderTopRightRadius: 4, borderBottomRightRadius: 4 }} />
          <Typography
            variant="body1"
            style={{ color: (processed ? greenIOS : blueIOS), fontSize: 14, lineHeight: 1, marginLeft: 8 }}
          >
            {processed ? "Processed" : "Newly Added"}
          </Typography>
        </div>
      ) : null}
      <div style={{ display: "flex", flexDirection: 'row', marginTop: 4 }}>
        <div style={{ display: "flex" }}>
          {FoodType(orderedItem.foodData.foodType)}
        </div>
        <div
          id="item-detail"
          style={{
            display: "flex",
            flex: 1,
            flexDirection: "column",
            marginLeft: 16
          }}
        >
          <Typography style={{ fontSize: 16, lineHeight: 1 }} variant="h6">{itemName}</Typography>
          {(orderedItem.addOns && orderedItem.addOns.length > 0) ? (
            <div style={{ display: 'flex', marginTop: 4, flexDirection: 'column' }}>
              {
                orderedItem.addOns?.flatMap(item => item.addOnOptionIds).map((optionId: string) => {
                  const addOnOptionData = addOnOptions?.find(item => item.addOnOptionId == optionId);
                  return addOnOptionData && getAddOnSummaryItem(optionId, currency, addOnOptionData);
                }
                )
              }
            </div>
          ) : null}
        </div>
        <div style={{ display: 'flex', flexDirection: 'row' }}>
          <div style={{ display: "flex", background: gray100, marginRight: 16, height: 'max-content' }}>
            <Typography
              style={{
                fontSize: 14, lineHeight: 1,
                paddingLeft: 8, paddingRight: 8, paddingTop: 2, paddingBottom: 2
              }}
              variant="subtitle1"
            >
              x{quantity}
            </Typography>
          </div>
          <Typography
            style={{
              fontSize: 16, fontWeight: 500, lineHeight: 1,
              marginLeft: 16, minWidth: 50, textAlign: 'end'
            }}
            variant="h6">
            {formatAmount(orderedItem.orderItemTotal, navigator.language, currency)}
          </Typography>
        </div>
      </div>
    </div>
  )
}

export default OrderItem;
