import { Button, Typography } from "@mui/material";
import { white, blueIOS, redIOS2 } from '../../res/colors';
import { OrderType } from "../../types/types";
import { OrderStatusEnum } from "../../utils/constants";
import { isOrderInTerminalState } from "../../utils/utils";

type OrderActionsProps = {
  order: OrderType;
  orderMore: (restaurantId: string, tableId: string) => void;
  cancelOrder: (orderId: string) => void;
};

const OrderActions = (props: OrderActionsProps) => {
  const canCancel = props.order.status === OrderStatusEnum.PENDING;
  var rightButtonMarginLeft = 4;
  if (!canCancel) {
    rightButtonMarginLeft = 0;
  }
  var orderMoreLabel = "Order More";
  if (isOrderInTerminalState(props.order.status)) {
    orderMoreLabel = "Place New Order";
  }
  return (
    <div
      style={{
        display: 'flex', position: 'fixed', bottom: 0,
        left: 0, right: 0, zIndex: 1250,
        alignItems: 'center', justifyContent: 'center',
        backgroundColor: white,
        padding: 8, width: 'auto'
      }}
    >
      {canCancel ?
        (
          <Button
            style={{ border: `1px solid ${redIOS2}`, flex: 1, marginRight: 4 }}
            onClick={() => props.cancelOrder(props.order.orderId)}
          >
            <Typography
              variant="body1"
              style={{ color: redIOS2 }}
            >
              Cancel Order
            </Typography>
          </Button>
        ) : null}
      <Button
        style={{ backgroundColor: blueIOS, flex: 1, marginLeft: rightButtonMarginLeft }}
        onClick={() => props.orderMore(props.order.restaurantId, props.order.tableId)}
      >
        <Typography
          variant="body1"
          style={{ color: white }}
        >
          {orderMoreLabel}
        </Typography>
      </Button>
    </div>
  );
};

export default OrderActions;
