import {
  Typography,
  Button,
  ButtonGroup,
  IconButton,
} from "@mui/material";
import { gray600, greenSO } from "../../res/colors";
import RemoveIcon from "@mui/icons-material/Remove";
import AddIcon from "@mui/icons-material/Add";
import { FoodUnavailableInfoType } from "../../types/types";


type CounterTypes = {
  count: number;
  unavailableInfo?: FoodUnavailableInfoType;
  setCount: (count: number, increase: boolean) => void;
  containerStyle: {}
};

const Counter = ({ count, setCount, containerStyle, unavailableInfo }: CounterTypes) => {
  var minusIconColor = greenSO;
  if (count < 0 || count === 0) {
    minusIconColor = gray600;
  }
  return (
    <div
      style={containerStyle}
    >
      {unavailableInfo ? (
        <Typography
          variant="body1"
          style={{ color: gray600, lineHeight: 1.3, fontSize: 9, padding: 8, textAlign: 'center' }}
        >
          {unavailableInfo.message}
        </Typography>
      ) : (
        <ButtonGroup size="small" aria-label="small outlined button group">
          {
            <IconButton
              aria-label="less"
              style={{ color: minusIconColor, padding: 0 }}
              onClick={() => (count - 1 <= 0) ? setCount(0, false) : setCount(count - 1, false)}
              disabled={count < 0 || count === 0}
            >
              <RemoveIcon />
            </IconButton>
          }
          <Button style={{ border: "none" }} disabled={true}>
            <Typography
              variant="body1"
              style={{ color: greenSO, fontWeight: 900, padding: 0 }}
            >
              {count}
            </Typography>
          </Button>
          {
            <IconButton
              aria-label="more"
              style={{ color: greenSO, padding: 0 }}
              onClick={() => setCount(count + 1, true)}
            >
              <AddIcon />
            </IconButton>
          }
        </ButtonGroup>
      )}
    </div>
  );
};

export default Counter;
