import { black, white } from "../../res/colors";
import 'react-toastify/dist/ReactToastify.css';
import WebsiteAppbar from "./WebsiteAppbar";
import { useEffect } from "react";
import { Box, Grid, Typography } from "@mui/material";
import { WebDivWrapper } from "./Home";
import WebsiteFooter from "./WebsiteFooter";



const Plans = () => {

  const priceContainer = (price: string) => {
    return (
      <div style={{ padding: 8 }}>
        <Typography
          variant="h5"
          noWrap
          textAlign={'center'}
          component="div"
          style={{ fontWeight: 500 }}
          lineHeight={1.2}>
          {price}
        </Typography>
        <Typography
          variant="subtitle1"
          noWrap
          textAlign={'center'}
          component="div"
          lineHeight={1.2}>
          per month
        </Typography>
      </div>
    )
  }

  const featureContainer = (title: string) => {
    return (
      <Typography
        variant="subtitle1"
        component="div"
        lineHeight={1.2}
        sx={{
          display: 'flex', flex: 1, textAlign: 'center',
          marginTop: '8px', marginBottom: '8px', color: black,
          alignItems: 'center', justifyContent: 'center', minHeight: '40px',
          paddingTop: '12px', paddingBottom: '12px',
          paddingLeft: '4px', paddingRight: '4px'
        }}>
        {title}
      </Typography>
    )
  }

  const divider = () => <div style={{ height: '1px', background: '#DDDCDB', marginLeft: '8px', marginRight: '8px' }} />

  useEffect(() => {
    document.title = "Bhojan - Plans";
  }, []);

  return (
    <WebDivWrapper>
      <div style={{ position: 'sticky', top: 0, zIndex: 200 }}>
        <WebsiteAppbar />
      </div>
      <Box
        style={{ marginTop: 16 }}
        sx={{ marginLeft: { xs: '8px', md: '64px' }, marginRight: { xs: '8px', md: '64px' } }}
      >
        <Grid container spacing={2} >
          <Grid item xs={4}>
            <Typography
              variant="h5"
              noWrap
              component="div"
              lineHeight={1.2}
              sx={{ display: 'flex', flex: 1, background: '#FFEBB4', borderRadius: '8px', color: black, alignItems: 'center', justifyContent: 'center', padding: '12px' }}>
              FREE
            </Typography>
          </Grid>
          <Grid item xs={4}>
            <Typography
              variant="h5"
              noWrap
              component="div"
              lineHeight={1.2}
              sx={{ display: 'flex', flex: 1, background: '#B5E4CD', borderRadius: '8px', color: black, alignItems: 'center', justifyContent: 'center', padding: '12px' }}>
              Plus
            </Typography>
          </Grid>
          <Grid item xs={4}>
            <Typography
              variant="h5"
              noWrap
              component="div"
              lineHeight={1.2}
              sx={{ display: 'flex', flex: 1, background: '#F4C5C0', borderRadius: '8px', color: black, alignItems: 'center', justifyContent: 'center', padding: '12px' }}>
              Pro
            </Typography>
          </Grid>
        </Grid>
        <Grid container spacing={2} style={{ marginTop: 12 }} >
          <Grid item xs={4}>
            <div style={{ display: 'flex', flexDirection: 'column', background: '#FDF7E8', flex: 1, padding: 4, borderRadius: '8px' }}>
              {priceContainer('₹0')}
              {divider()}
              {featureContainer("Unlimited Food Inventory")}
              {divider()}
              {featureContainer("Unlimited Table QR Codes")}
              {divider()}
              {featureContainer("200 monthly orders limit")}
              {divider()}
              {featureContainer("-")}
              {divider()}
              {featureContainer("-")}
              {divider()}
              {featureContainer("-")}
            </div>
          </Grid>
          <Grid item xs={4}>
            <div style={{ display: 'flex', flexDirection: 'column', background: '#EAF4ED', flex: 1, padding: 4, borderRadius: '8px' }}>
              {priceContainer('₹200')}
              {divider()}
              {featureContainer("Unlimited Food Inventory")}
              {divider()}
              {featureContainer("Unlimited Table QR Codes")}
              {divider()}
              {featureContainer("Unlimited Orders")}
              {divider()}
              {featureContainer("Reports & Analytics")}
              {divider()}
              {featureContainer("WhatsApp Bill Sharing")}
              {divider()}
              {featureContainer("-")}
            </div>
          </Grid>
          <Grid item xs={4}>
            <div style={{ display: 'flex', flexDirection: 'column', background: '#F8ECE9', flex: 1, padding: 4, borderRadius: '8px' }}>
              {priceContainer('₹300')}
              {divider()}
              {featureContainer("Unlimited Food Inventory")}
              {divider()}
              {featureContainer("Unlimited Table QR Codes")}
              {divider()}
              {featureContainer("Unlimited Orders")}
              {divider()}
              {featureContainer("Reports & Analytics")}
              {divider()}
              {featureContainer("WhatsApp Bill Sharing")}
              {divider()}
              {featureContainer("Staff Management")}
            </div>
          </Grid>
        </Grid>
      </Box>
      <WebsiteFooter />
      <div style={{ height: 50 }} />
    </WebDivWrapper>
  );
};

export default Plans;
