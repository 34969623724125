import { FoodTypeEnum } from "../../utils/constants";
import VegIcon from "../../images/veg_icon.svg";
import NonVegIcon from "../../images/non_veg_icon.svg";
import EggIcon from "../../images/egg_icon.svg";

const FoodType = (type: FoodTypeEnum) => {
  var foodTypeIcon;
  switch (type) {
    case FoodTypeEnum.VEG: {
      foodTypeIcon = VegIcon;
      break;
    }
    case FoodTypeEnum.NON_VEG: {
      foodTypeIcon = NonVegIcon;
      break;
    }
    case FoodTypeEnum.EGG: {
      foodTypeIcon = EggIcon;
      break;
    }
  }

  return <img src={foodTypeIcon} height={16} width={16} />;
};

export default FoodType;
