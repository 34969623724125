import { Divider, Typography } from "@mui/material";
import { black, blueIOS, greenIOS, orange, redIOS2, white } from "../../res/colors";
import { OrderStatusEnum } from "../../utils/constants";
import { getOrderStatusLabel, isOrderInTerminalState } from "../../utils/utils";
import HorizontalLoading from "./HorizontalLoading";

export type OrderInfoTypes = {
  tableName: string;
  orderStatus: OrderStatusEnum;
};

const OrderInfo = (orderInfo: OrderInfoTypes) => {
  var statusColor = blueIOS;
  switch (orderInfo.orderStatus) {
    case OrderStatusEnum.PENDING:
      statusColor = blueIOS;
      break;
    case OrderStatusEnum.IN_PROCESS, OrderStatusEnum.PARTIALLY_PROCESSED:
      statusColor = orange;
      break;
    case OrderStatusEnum.COMPLETED:
      statusColor = greenIOS;
      break;
    case OrderStatusEnum.CANCELLED:
      statusColor = redIOS2;
      break;
  }
  return (
    <div
      id="order-info"
      style={{
        display: 'flex',
        flexDirection: 'column',
        backgroundColor: white,
      }}>
      <div style={{ display: 'flex', flexDirection: 'row', paddingLeft: 16, paddingRight: 16, paddingTop: 4, paddingBottom: 4 }}>
        <div style={{ display: 'flex', flexDirection: 'row' }}>
          <Typography
            style={{ color: black }}
            variant="subtitle1"
          >
            Order Table:
          </Typography>
          <Typography
            style={{ color: blueIOS, marginLeft: 4 }}
            variant="subtitle1"
          >
            {orderInfo.tableName}
          </Typography>
        </div>
        <div style={{ flex: 1 }} />
        <div style={{ display: 'flex', flexDirection: 'row' }}>
          <Typography
            style={{ color: black }}
            variant="subtitle1"
          >
            Status:
          </Typography>
          <div>
            <Typography
              style={{ color: statusColor, marginLeft: 4 }}
              variant="subtitle1"
            >
              {getOrderStatusLabel(orderInfo.orderStatus)}
            </Typography>
            {(isOrderInTerminalState(orderInfo.orderStatus)) ? null : (
              <HorizontalLoading color={redIOS2} style={{ color: statusColor, marginLeft: 4 }} />
            )}
          </div>
        </div>
      </div>
      <Divider />
    </div>
  );
};

export default OrderInfo;
