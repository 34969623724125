import { black, gray900, white } from "../../res/colors";
import 'react-toastify/dist/ReactToastify.css';
import styled from "styled-components";
import WebsiteAppbar from "./WebsiteAppbar";
import { useEffect } from "react";
import { CardMedia, Typography } from "@mui/material";
import ranjan from '../images/ranjan.webp';
import shilpa from '../images/shilpa.webp';
import step1 from '../images/step1.svg';
import playStore from '../images/play_store.svg';
import step2 from '../images/step2.svg';
import food from '../images/food.svg';
import step3 from '../images/step3.svg';
import menu from '../images/menu.svg';
import step4 from '../images/step4.svg';
import qrCode from '../images/qr.svg';
import empty from '../images/empty.png';
import step1Arrow from '../images/step1_arrow.svg';
import step2Arrow from '../images/step2_arrow.svg';
import step3Arrow from '../images/step3_arrow.svg';
import blueBg from '../images/blue_bg.jpg';
import WebsiteFooter from "./WebsiteFooter";


const Home = () => {

  useEffect(() => {
    document.title = "Bhojan - Restaurant Management | QR Menu";
  }, []);

  return (
    <WebDivWrapper>
      <div style={{ position: 'sticky', top: 0, zIndex: 200 }}>
        <WebsiteAppbar />
      </div>
      <>
        <h1 style={{ background: gray900, display: 'flex', flexDirection: 'column' }}>
          <Typography
            variant="h5"
            noWrap
            component="div"
            lineHeight={1.2}
            sx={{ display: 'flex', fontWeight: 400, color: white, marginLeft: '12px', whiteSpace: 'normal', textAlign: 'center', alignItems: 'center', justifyContent: 'center', padding: '20px' }}>
            QR Menu
            <br />
            Restaurant Contactless Ordering | Scan to Order
          </Typography>
        </h1>
        <Typography
          variant="subtitle1"
          noWrap
          component="div"
          lineHeight={1.2}
          sx={{ display: 'flex', color: black, marginLeft: '12px', whiteSpace: 'normal', alignItems: 'center', justifyContent: 'center', padding: '20px' }}>
          Bhojan app is a comprehensive restaurant management system that streamlines every aspect of your business,
          starting from menu creation to online order processing and order fulfillment.
          It empowers you to efficiently run your restaurant with ease.
          <br />
          <br />
          Our solutions are specifically tailored for small to medium-sized restaurants,
          eliminating the need for a dedicated computer as you can effortlessly manage all operations from your phone.
          <br />
          <br />
          Moreover, Bhojan offers customers a user-friendly and intuitive interface, ensuring a pleasant and familiar experience.
        </Typography>
        <h6 style={{ backgroundImage: `url(${blueBg})`, backgroundSize: 'cover', display: 'flex', flexDirection: 'column' }}>
          <Typography
            variant="h5"
            noWrap
            component="div"
            lineHeight={1.2}
            sx={{ display: 'flex', fontWeight: 700, color: white, marginTop: '12px', whiteSpace: 'normal', textAlign: 'center', alignItems: 'center', justifyContent: 'center' }}>
            VISION
          </Typography>
          <Typography
            variant="h5"
            noWrap
            component="div"
            lineHeight={1.2}
            sx={{ display: 'flex', color: white, whiteSpace: 'normal', textAlign: 'center', alignItems: 'center', justifyContent: 'center', padding: '20px' }}>
            Our vision is to empower millions of MSME (Micro, Small, and Medium) restaurants by leveraging technology to enhance their ability to serve customers in an exceptional manner.
          </Typography>
        </h6>
        <div style={{ display: 'flex', flexDirection: 'column', marginTop: '16px', background: '#DBE5F8', paddingLeft: '20px', paddingRight: '20px', paddingBottom: '20px' }}>
          <Typography
            variant="h5"
            noWrap
            component="div"
            lineHeight={1.2}
            sx={{ display: 'flex', color: black, marginLeft: '12px', whiteSpace: 'normal', textAlign: 'center', alignItems: 'center', justifyContent: 'center', padding: '20px' }}>
            <p>An Initiative by <b>IIT</b> and <b>BITS Alumni</b></p>
          </Typography>
          <div style={{ display: 'flex', flexDirection: 'row' }}>
            <div style={{ display: 'flex', justifyContent: 'center', flex: 1 }}>
              <CardMedia
                component="img"
                sx={{
                  width: 60,
                  height: 60,
                  borderRadius: 30
                }}
                image={ranjan}
                alt="Alternate image"
              />
              <div style={{ marginLeft: '8px' }}>
                <Typography
                  variant="h5"
                  noWrap
                  component="div"
                  lineHeight={1.2}
                  sx={{ display: 'flex', color: black, whiteSpace: 'normal', textAlign: 'left' }}>
                  <b>Ranjan Malav</b>
                </Typography>
                <Typography
                  variant="h6"
                  noWrap
                  component="div"
                  lineHeight={1.2}
                  sx={{ fontWeight: 400, display: 'flex', color: black, whiteSpace: 'normal', textAlign: 'left', marginTop: '4px' }}>
                  IIT Roorkee
                </Typography>
              </div>
            </div>
            <div style={{ display: 'flex', justifyContent: 'center', flex: 1 }}>
              <CardMedia
                component="img"
                sx={{
                  width: 60,
                  height: 60,
                  borderRadius: 30
                }}
                image={shilpa}
                alt="Alternate image"
              />
              <div style={{ marginLeft: '8px' }}>
                <Typography
                  variant="h5"
                  noWrap
                  component="div"
                  lineHeight={1.2}
                  sx={{ display: 'flex', color: black, whiteSpace: 'normal', textAlign: 'left' }}>
                  <b>Shilpa Mandara</b>
                </Typography>
                <Typography
                  variant="h6"
                  noWrap
                  component="div"
                  lineHeight={1.2}
                  sx={{ fontWeight: 400, display: 'flex', color: black, whiteSpace: 'normal', textAlign: 'left', marginTop: '4px' }}>
                  BITS Pilani
                </Typography>
              </div>
            </div>
          </div>
        </div>
        <div>
          <Typography
            variant="h5"
            noWrap
            component="div"
            lineHeight={1.2}
            sx={{ display: 'flex', color: black, marginLeft: '12px', marginTop: '16px', whiteSpace: 'normal', textAlign: 'center', alignItems: 'center', justifyContent: 'center', padding: '20px' }}>
            Get started with 4 simple steps
          </Typography>
          <div style={{ display: 'flex', background: '#FCF3DA', flexDirection: 'column', padding: '16px' }}>
            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center' }}>
              <CardMedia
                component="img"
                sx={{
                  width: 60,
                  height: 60
                }}
                image={step1}
                alt="Alternate image"
              />
              <Typography
                variant="h6"
                noWrap
                component="div"
                lineHeight={1.2}
                sx={{ display: 'flex', maxWidth: 600, flex: 1, color: black, marginLeft: '12px', whiteSpace: 'normal' }}>
                Install the Bhojan app from the Play Store
              </Typography>
              <CardMedia
                component="img"
                sx={{
                  width: 60,
                  height: 60
                }}
                image={playStore}
                alt="Alternate image"
              />
            </div>
            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center' }}>
              <div style={{ width: 60, height: 60, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <CardMedia
                  component="img"
                  sx={{
                    width: 30,
                    height: 36
                  }}
                  image={step1Arrow}
                  alt="Alternate image"
                />
              </div>
              <Typography
                variant="h5"
                noWrap
                component="div"
                lineHeight={1.2}
                sx={{ display: 'flex', maxWidth: 600, flex: 1, color: black, marginLeft: '12px', whiteSpace: 'normal' }}>
              </Typography>
              <CardMedia
                component="img"
                sx={{
                  width: 60,
                  height: 60
                }}
                image={empty}
                alt="Alternate image"
              />
            </div>
            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center' }}>
              <CardMedia
                component="img"
                sx={{
                  width: 60,
                  height: 60
                }}
                image={step2}
                alt="Alternate image"
              />
              <Typography
                variant="h6"
                noWrap
                component="div"
                lineHeight={1.2}
                sx={{ display: 'flex', maxWidth: 600, flex: 1, color: black, marginLeft: '12px', whiteSpace: 'normal' }}>
                Create Food items that you serve
              </Typography>
              <CardMedia
                component="img"
                sx={{
                  width: 60,
                  height: 60
                }}
                image={food}
                alt="Alternate image"
              />
            </div>
            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center' }}>
              <div style={{ width: 60, height: 60, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <CardMedia
                  component="img"
                  sx={{
                    width: 30,
                    height: 36
                  }}
                  image={step2Arrow}
                  alt="Alternate image"
                />
              </div>
              <Typography
                variant="h5"
                noWrap
                component="div"
                lineHeight={1.2}
                sx={{ display: 'flex', maxWidth: 600, flex: 1, color: black, marginLeft: '12px', whiteSpace: 'normal' }}>
              </Typography>
              <CardMedia
                component="img"
                sx={{
                  width: 60,
                  height: 60
                }}
                image={empty}
                alt="Alternate image"
              />
            </div>
            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center' }}>
              <CardMedia
                component="img"
                sx={{
                  width: 60,
                  height: 60
                }}
                image={step3}
                alt="Alternate image"
              />
              <Typography
                variant="h6"
                noWrap
                component="div"
                lineHeight={1.2}
                sx={{ display: 'flex', maxWidth: 600, flex: 1, color: black, marginLeft: '12px', whiteSpace: 'normal' }}>
                Design your menu using created Food items
              </Typography>
              <CardMedia
                component="img"
                sx={{
                  width: 60,
                  height: 60
                }}
                image={menu}
                alt="Alternate image"
              />
            </div>
            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center' }}>
              <div style={{ width: 60, height: 60, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <CardMedia
                  component="img"
                  sx={{
                    width: 30,
                    height: 36
                  }}
                  image={step3Arrow}
                  alt="Alternate image"
                />
              </div>
              <Typography
                variant="h5"
                noWrap
                component="div"
                lineHeight={1.2}
                sx={{ display: 'flex', maxWidth: 600, flex: 1, color: black, marginLeft: '12px', whiteSpace: 'normal' }}>
              </Typography>
              <CardMedia
                component="img"
                sx={{
                  width: 60,
                  height: 60
                }}
                image={empty}
                alt="Alternate image"
              />
            </div>
            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center' }}>
              <CardMedia
                component="img"
                sx={{
                  width: 60,
                  height: 60
                }}
                image={step4}
                alt="Alternate image"
              />
              <Typography
                variant="h6"
                noWrap
                component="div"
                lineHeight={1.2}
                sx={{ display: 'flex', maxWidth: 600, flex: 1, color: black, marginLeft: '12px', whiteSpace: 'normal' }}>
                Print Table specific QR codes to start taking orders
              </Typography>
              <CardMedia
                component="img"
                sx={{
                  width: 60,
                  height: 60
                }}
                image={qrCode}
                alt="Alternate image"
              />
            </div>
            <Typography
              variant="h5"
              noWrap
              component="div"
              lineHeight={1.2}
              sx={{ display: 'flex', flex: 1, color: black, marginLeft: '12px', marginTop: '20px', whiteSpace: 'normal', justifyContent: 'center', padding: '20px' }}>
              Voila! You are all set to start taking orders.
            </Typography>
          </div>
        </div>
        <WebsiteFooter />
        <div style={{ height: 50 }} />
      </>
    </WebDivWrapper>
  );
};


export const WebDivWrapper = styled.div`
  width: 100%;
  min-height: 100vh;
  position: relative;
  max-width: 1920px;
  &.fixed {
    height: 100vh;
    overflow-y: hidden;
  }

  #search-section {
    button circle {
      fill: none;
    }
    main {
      display: block;
    }
    main > div:first-of-type > div:nth-of-type(2) {
      left: 50%;
      transform: translateX(-50%);
    }
  }
`;

export default Home;
