import { Divider, Typography } from "@mui/material";
import ExpandMoreOutlinedIcon from '@mui/icons-material/ExpandMoreOutlined';
import Counter from "./Counter";
import FoodType from "../common/FoodType";
import { blueIOS, gray400, gray600, white } from "../../res/colors";
import { formatAmount, getFoodTypeWithAddOns, getTotalItemPrice } from "../../utils/utils";
import { AddOnOptionType, FoodItemType, SelectedItemType } from "../../types/types";


type OrderSummaryItemType = {
  selectedItem: SelectedItemType;
  currency: string;
  handleUserSelection: (foodSelection: SelectedItemType, foodData: FoodItemType) => void;
  customizeOrderItem: (orderItem: SelectedItemType) => void;
};

export const getAddOnSummaryItem = (
  optionId: string,
  currency: string,
  addOnOptionData: AddOnOptionType
) => {
  return (
    <div key={optionId} style={{ display: "flex", flexDirection: 'column', marginTop: 6 }}>
      <Typography
        style={{
          fontSize: 16, lineHeight: 1, color: gray600
        }} variant="body1">
        {`${addOnOptionData.name} (${formatAmount(addOnOptionData.price, navigator.language, currency)})`}
      </Typography>
    </div>
  )
}

const OrderSummaryItem = ({
  selectedItem, handleUserSelection, currency, customizeOrderItem
}: OrderSummaryItemType) => {
  const { quantity } = selectedItem;
  var { foodData, addOns } = selectedItem;

  const handleCounter = (newCount: number) => {
    selectedItem.quantity = newCount;
    handleUserSelection(selectedItem, foodData);
  };

  var itemName = foodData.name
  if (addOns?.length && addOns?.length > 0) {
    itemName = `${foodData.name} (${formatAmount(foodData.price, navigator.language, currency)})`
  }
  const addOnOptions = foodData.addOns?.flatMap(item => item.addOnOptions);
  const foodType = getFoodTypeWithAddOns(foodData, addOns);

  return (
    <div style={{ display: 'flex', flexDirection: 'column' }}>
      <div style={{ display: "flex", flexDirection: 'row', marginTop: 12 }}>
        <div style={{ display: "flex", marginTop: 8 }}>
          {FoodType(foodType)}
        </div>
        <div
          id="item-detail"
          style={{
            display: "flex",
            flex: 1,
            flexDirection: "column",
            marginLeft: 16,
            marginTop: 8
          }}
        >
          <Typography style={{ fontSize: 16, lineHeight: 1 }} variant="h6">{itemName}</Typography>
          {(addOns && addOns.length > 0) ? (
            <div style={{ display: 'flex', marginTop: 4, flexDirection: 'column' }}>
              {
                addOns?.flatMap(item => item.addOnOptionIds).map((optionId: string) => {
                  const addOnOptionData = addOnOptions?.find(item => item.addOnOptionId == optionId);
                  return addOnOptionData && getAddOnSummaryItem(optionId, currency, addOnOptionData);
                }
                )
              }
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  flexWrap: 'wrap',
                  marginTop: 4
                }}
                onClick={() => customizeOrderItem(selectedItem)}
              >
                <Typography
                  style={{
                    fontSize: 12, lineHeight: 1, color: blueIOS
                  }} variant="body1">
                  Edit
                </Typography>
                <ExpandMoreOutlinedIcon fontSize="small" htmlColor={blueIOS} />
              </div>
            </div>
          ) : null}
        </div>
        <div style={{ display: 'flex', flexDirection: 'row' }}>
          <Counter
            count={quantity}
            setCount={handleCounter}
            containerStyle={
              {
                display: "flex",
                border: `1px solid ${gray400}`,
                borderRadius: 4,
                background: white,
                width: 90,
                height: "max-content"
              }
            }
          />
          <Typography
            style={{
              fontSize: 16, fontWeight: 500, lineHeight: 1,
              marginTop: 8, marginLeft: 16, minWidth: 50, textAlign: 'end'
            }} variant="h6">
            {formatAmount(selectedItem.orderItemTotal, navigator.language, currency)}
          </Typography>
        </div>
      </div>
    </div>
  )
}

export default OrderSummaryItem;
