import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage";

const firebaseConfigDev = {
  apiKey: "AIzaSyBAqBklnuDNj24f57BRi1DSqGNsGB320qg",
  authDomain: "bhojan-dev.firebaseapp.com",
  projectId: "bhojan-dev",
  storageBucket: "bhojan-dev.appspot.com",
  messagingSenderId: "779179361377",
  appId: "1:779179361377:web:3edefaa2531f09167f9620",
  measurementId: "G-6T8Z3GDPFV"
};

const firebaseConfig = {
  apiKey: "AIzaSyCupgGhjMwNSnMus_GnRh7fxJWsCKtLYSM",
  authDomain: "bhojan-301504.firebaseapp.com",
  projectId: "bhojan-301504",
  storageBucket: "bhojan-301504.appspot.com",
  messagingSenderId: "290149932953",
  appId: "1:290149932953:web:1a18bb0e5189f97564fd08",
  measurementId: "G-CFZBVQ350Q",
};

const firebaseApp = initializeApp(process.env.NODE_ENV === 'production' ? firebaseConfig : firebaseConfigDev);

export default firebaseApp;
export const firestore = getFirestore(firebaseApp);
export const firebaseStorage = getStorage(firebaseApp);
export const analytics = getAnalytics(firebaseApp);