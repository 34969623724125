import React, { useEffect, useState } from "react";
import CacheBuster from 'react-cache-buster';
import packageInfo from '../package.json';
import { doc, getDoc } from "firebase/firestore";
import { ref, getDownloadURL } from "firebase/storage";
import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";
import { Routes, Route, useLocation } from 'react-router-dom';
import { FoodItemType, ImageUrlType, RestaurantType } from "./types/types";
import OrderDetail from "./components/order_detail/OrderDetail";
import { isRestaurantOpenFn, removeUnavailableAddOns } from "./utils/utils";
import { CircularProgress } from "@mui/material";
import Home from "./static_website/components/Home";
import Features from "./static_website/components/Features";
import Plans from "./static_website/components/Plans";
import ContactUs from "./static_website/components/ContactUs";
import { firebaseStorage, firestore } from "./firebase";

const LoadingDialog = React.lazy(() => import('./components/common/LoadingDialog'));
const Menu = React.lazy(() => import('./components/ordering/Menu'));

type AppProps = {
  data?: RestaurantType;
};

Sentry.init({
  dsn: "https://9613521436654bd282a8261d87e44156@o4503948934250496.ingest.sentry.io/4503948935102464",
  integrations: [new BrowserTracing()],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,
});

const App: React.FC<AppProps> = () => {
  const [restaurantData, setRestaurantData] = useState<RestaurantType | undefined>();
  const [tableId, setTableId] = useState<string | undefined>();
  const [fetchingRestaurant, setFetchingRestaurant] = useState<boolean>(false);
  const [imageUrls, setImageUrls] = useState<ImageUrlType>({});
  const location = useLocation();
  const [isRestaurantOffline, setRestaurantOffline] = useState<boolean>(false);
  const isProduction = process.env.NODE_ENV === 'production';

  useEffect(() => {
    if (!restaurantData && location.pathname === "/order") {
      getRestaurantData()
    }
  }, [location]);

  const getRestaurantData = async () => {
    if (fetchingRestaurant) return;
    setFetchingRestaurant(true);

    const queryParams = new URLSearchParams(window.location.search);
    var restaurantId = queryParams.get("restaurantId");
    var tableId = queryParams.get("tableId");
    if (queryParams.get("rId")) {
      restaurantId = queryParams.get("rId")
    }
    if (queryParams.get("tId")) {
      tableId = queryParams.get("tId")
    }
    if (restaurantId != null) {
      const restaurantDocRef = doc(firestore, "restaurants", restaurantId);
      const restaurantSnap = await getDoc(restaurantDocRef);

      if (restaurantSnap.exists()) {
        var data = restaurantSnap.data() as RestaurantType;
        setRestaurantOffline(!isRestaurantOpenFn(data));
        // Clean data, remove unavailable addOns but not addOnOptions
        data = removeUnavailableAddOns(data);
        setRestaurantData(data);
        setTableId(tableId as any);
        getAllFoodImages(data.foodItems.filter(item => item.imageUrl && item.imageUrl?.length > 0));
        setFetchingRestaurant(false);
      } else {
        // doc.data() will be undefined in this case
        console.log("No such document!");
        setRestaurantData(undefined);
        setFetchingRestaurant(false);
      }
    }
  };

  const getAllFoodImages = (foodItems: FoodItemType[]) => {
    foodItems.forEach(foodItem => {
      // Get image URL if doesn't exist in the state
      const imageUrlData = imageUrls[foodItem.foodId];
      if (!imageUrlData || (imageUrlData.foodImageUrl.length === 0 && !imageUrlData.downloaded)) {
        getDownloadURL(ref(firebaseStorage, foodItem.imageUrl))
          .then((url) => {
            setImageUrls((prevState) => ({
              ...prevState, [foodItem.foodId]: {
                foodId: foodItem.foodId,
                foodImageUrl: url,
                downloaded: true
              }
            }));
          })
          .catch((error) => {
            console.log(error);
            Sentry.captureException(error);
          });
      }
    });
  }

  return (
    <CacheBuster
      currentVersion={packageInfo.version}
      isEnabled={isProduction} //If false, the library is disabled.
      isVerboseMode={true} //If true, the library writes verbose logs to console.
      loadingComponent={<div style={{ width: '100%', height: '100vh', alignItems: 'center', justifyContent: 'center', display: 'flex' }}><CircularProgress /></div>} //If not pass, nothing appears at the time of new version check.
      metaFileDirectory={'.'} //If public assets are hosted somewhere other than root on your server.
    >
      <Routes>
        <Route path="/order" element={
          restaurantData ? (
            <Menu
              restaurant={restaurantData}
              imageUrls={imageUrls}
              tableId={tableId}
              isRestaurantOffline={isRestaurantOffline}
            />
          ) : (
            <LoadingDialog open={true} message="Something good is cooking.." />
          )
        } />
        <Route path="/orders/:id" element={<OrderDetail restaurant={restaurantData} />} />
        <Route path="/" element={<Home />} />
        <Route path="/home" element={<Home />} />
        <Route path="/features" element={<Features />} />
        <Route path="/plans" element={<Plans />} />
        <Route path="/contact_us" element={<ContactUs />} />
        <Route path="/qr_ordering" element={<Home />} />
        <Route path="/scan_to_order" element={<Home />} />
      </Routes>
    </CacheBuster>
  );
};

export default App;
