import { black, blueIOS, white } from "../../res/colors";
import 'react-toastify/dist/ReactToastify.css';
import WebsiteAppbar from "./WebsiteAppbar";
import { useEffect } from "react";
import { CardMedia, Typography } from "@mui/material";
import phone from '../images/phone.svg';
import email from '../images/email.svg';
import { WebDivWrapper } from "./Home";
import WebsiteFooter from "./WebsiteFooter";


const ContactUs = () => {

  useEffect(() => {
    document.title = "Bhojan - Contact Us";
  }, []);

  return (
    <WebDivWrapper>
      <div style={{ display: 'flex', flexDirection: 'column', height: '100vh' }}>
        <div style={{ position: 'sticky', top: 0, zIndex: 200 }}>
          <WebsiteAppbar />
        </div>
        <div style={{ display: 'flex', flexDirection: 'column', flex: 1 }}>
          <Typography
            variant="h6"
            noWrap
            component="div"
            lineHeight={1.2}
            sx={{ display: 'flex', color: black, marginLeft: '12px', whiteSpace: 'normal', alignItems: 'center', justifyContent: 'center', padding: '20px' }}>
            Reach out to us
          </Typography>
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <CardMedia
              component="img"
              sx={{
                width: 30,
                height: 30
              }}
              image={phone}
              alt="Alternate image"
            />
            <Typography
              variant="h5"
              noWrap
              component="div"
              lineHeight={1.2}
              sx={{ display: 'flex', marginLeft: '16px', color: black, whiteSpace: 'normal', textAlign: 'center' }}>
              <a href="tel:+91-9000194690" style={{ color: blueIOS }}>+91-9000194690</a>
            </Typography>
          </div>
          <div style={{ display: 'flex', marginTop: '16px', justifyContent: 'center' }}>
            <CardMedia
              component="img"
              sx={{
                width: 30,
                height: 30
              }}
              image={email}
              alt="Alternate image"
            />
            <Typography
              variant="h5"
              noWrap
              component="div"
              lineHeight={1.2}
              sx={{ display: 'flex', marginLeft: '16px', color: black, whiteSpace: 'normal', textAlign: 'center' }}>
              <a href="mailto:app.bhojan@gmail.com" style={{ color: blueIOS }}>app.bhojan@gmail.com</a>
            </Typography>
          </div>
        </div>
        <WebsiteFooter />
        <div style={{ height: 50 }} />
      </div>
    </WebDivWrapper>
  );
};

export default ContactUs;
