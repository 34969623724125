import { Card, Divider, IconButton, Typography } from "@mui/material";
import ContentCopyOutlinedIcon from '@mui/icons-material/ContentCopyOutlined';
import { black, gray100 } from "../../res/colors";
import GPay from "../../images/ic_gpay.svg";
import PayTM from "../../images/ic_paytm.svg";
import UPI from "../../images/ic_upi.svg";
import { PaymentInfoType } from "../../types/types";
import { WalletTypeEnum } from "../../utils/constants";

type WalletOptionsTypes = {
  walletId?: string;
  walletType: WalletTypeEnum;
};

const WalletOptions = (walletData: WalletOptionsTypes) => {
  var icon;
  switch (walletData.walletType) {
    case WalletTypeEnum.GPAY:
      icon = GPay;
      break;
    case WalletTypeEnum.PAYTM:
      icon = PayTM;
      break;
    case WalletTypeEnum.UPI:
      icon = UPI;
      break;
  }
  return (
    <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginTop: 12 }}>
      <img src={icon} height={20} width={50} />
      <Typography
        style={{ minWidth: 200, maxWidth: 300, overflow: 'hidden', textOverflow: 'ellipsis', color: black, marginLeft: 16, paddingLeft: 16, paddingRight: 16, paddingTop: 2, paddingBottom: 2, backgroundColor: gray100, borderRadius: 4 }}
        variant="subtitle1"
      >
        {walletData.walletId ? walletData.walletId : (<div>&nbsp;</div>)}
      </Typography>
      <IconButton
        aria-label="copy"
        style={{ color: black, padding: 0, marginLeft: 16 }}
        onClick={() => { navigator.clipboard.writeText(walletData.walletId ? walletData.walletId : "") }}
      >
        <ContentCopyOutlinedIcon />
      </IconButton>
    </div>
  )
}

const PaymentDetails = (paymentInfo: PaymentInfoType) => {
  var cardAccepted;
  if (paymentInfo.debitCardAccepted && paymentInfo.creditCardAccepted) {
    cardAccepted = "Credit & Debit card is accepted"
  } else if (paymentInfo.debitCardAccepted) {
    cardAccepted = "Debit card is accepted"
  } else if (paymentInfo.creditCardAccepted) {
    cardAccepted = "Credit card is accepted"
  }

  return (
    <Card
      id="payment-details"
      variant="outlined"
      style={{
        margin: 16, paddingLeft: 16, paddingTop: 8, paddingBottom: 8, paddingRight: 16,
        border: "none"
      }}>
      <Typography
        style={{ color: black, fontWeight: 500 }}
        variant="subtitle1"
      >
        Payment Options
      </Typography>
      <Divider style={{ marginTop: 8 }} />
      {paymentInfo ? (
        <div id="wallet-details" style={{ marginTop: 16 }}>
          <WalletOptions walletId={paymentInfo.gpayId} walletType={WalletTypeEnum.GPAY} />
          <WalletOptions walletId={paymentInfo.payTMmId} walletType={WalletTypeEnum.PAYTM} />
          <WalletOptions walletId={paymentInfo.upiId} walletType={WalletTypeEnum.UPI} />
          {paymentInfo.cashAccepted ? (
            <Typography
              style={{ color: black, marginTop: 16, lineHeight: 1 }}
              variant="subtitle1"
            >
              Cash is accepted
            </Typography>) : null}
          {cardAccepted ? (
            <Typography
              style={{ color: black, marginTop: 16, lineHeight: 1 }}
              variant="subtitle1"
            >
              {cardAccepted}
            </Typography>) : null}
        </div>
      ) : null}
    </Card>
  );
};

export default PaymentDetails;
