import Box from '@mui/material/Box';
import { LocalPhoneOutlined, EmailOutlined } from '@mui/icons-material';
import { black, gray600, white } from '../../res/colors';
import logo from '../../logo.svg';
import Facebook from "../images/facebook.svg";
import Instagram from "../images/instagram.svg";
import Youtube from "../images/youtube.svg";
import { CardMedia, IconButton, Typography } from '@mui/material';


const WebsiteFooter = () => {
    return (
        <div style={{ backgroundColor: white, width: '100%' }}>
            {/* Footer when large screen */}
            <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }} style={{ marginLeft: 16, marginTop: '16px', alignItems: 'center' }}>
                <CardMedia
                    component="img"
                    sx={{
                        width: 30,
                        height: 30,
                        display: { xs: 'none', md: 'flex' },
                        mr: 1
                    }}
                    image={logo}
                    alt="Alternate image"
                />

                <Typography
                    variant="h6"
                    noWrap
                    component="a"
                    href="/"
                    sx={{
                        mr: 2,
                        display: { xs: 'none', md: 'flex' },
                        color: black,
                        textDecoration: 'none',
                    }}
                >
                    Bhojan App
                </Typography>
                <IconButton
                    size="large"
                    aria-label="show more"
                    aria-haspopup="true"
                    color="inherit"
                    onClick={() => { window.open('https://www.youtube.com/@bhojan_app', '_blank') }}>
                    <img src={Youtube} height={24} width={24} alt="Youtube" />
                </IconButton>
                <IconButton
                    size="large"
                    aria-label="show more"
                    aria-haspopup="true"
                    color="inherit"
                    onClick={() => { window.open('https://www.facebook.com/app.bhojan', '_blank') }}>
                    <img src={Facebook} height={24} width={24} alt="Facebook" />
                </IconButton>
                <IconButton
                    size="large"
                    aria-label="show more"
                    aria-haspopup="true"
                    color="inherit"
                    onClick={() => { window.open('https://instagram.com/bhojan.app', '_blank') }}>
                    <img src={Instagram} height={24} width={24} alt="Instagram" />
                </IconButton>
                <IconButton
                    size="large"
                    aria-label="show more"
                    aria-haspopup="true"
                    style={{ backgroundColor: 'transparent', marginLeft: '16px' }}
                    sx={{ color: gray600 }}
                    onClick={() => { }}>
                    <LocalPhoneOutlined />
                    <Typography
                        variant="h6"
                        noWrap
                        component="div"
                        lineHeight={1.2}
                        sx={{ display: 'flex', marginLeft: '8px', whiteSpace: 'normal', textAlign: 'center' }}>
                        <a href="tel:+91-9000194690" style={{ color: gray600, textDecoration: 'none' }}>+91-9000194690</a>
                    </Typography>
                </IconButton>
                <IconButton
                    size="large"
                    aria-label="show more"
                    aria-haspopup="true"
                    style={{ backgroundColor: 'transparent' }}
                    sx={{ color: gray600 }}
                    onClick={() => { }}>
                    <EmailOutlined />
                    <Typography
                        variant="h6"
                        noWrap
                        component="div"
                        lineHeight={1.2}
                        sx={{ display: 'flex', marginLeft: '8px', whiteSpace: 'normal', textAlign: 'center' }}>
                        <a href="mailto:app.bhojan@gmail.com" style={{ color: gray600, textDecoration: 'none' }}>app.bhojan@gmail.com</a>
                    </Typography>
                </IconButton>
            </Box>

            {/* Footer when small screen */}
            <Box sx={{ display: { xs: 'flex', md: 'none' } }} style={{ flexDirection: 'column' }}>
                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginTop: 16, marginLeft: 28 }} >
                    <CardMedia
                        component="img"
                        sx={{
                            width: 30,
                            height: 30,
                            mr: 1
                        }}
                        image={logo}
                        alt="Alternate image"
                    />

                    <Typography
                        variant="h6"
                        noWrap
                        component="a"
                        href="/"
                        sx={{
                            mr: 2,
                            color: black,
                            textDecoration: 'none',
                        }}
                    >
                        Bhojan App
                    </Typography>
                </div>
                <Box style={{ marginTop: 16, marginLeft: 16 }}>
                    <IconButton
                        size="large"
                        aria-label="show more"
                        aria-haspopup="true"
                        sx={{ color: gray600 }}
                        onClick={() => { window.open('https://www.youtube.com/@bhojan_app', '_blank') }}>
                        <img src={Youtube} height={24} width={24} alt="Youtube" />
                    </IconButton>
                    <IconButton
                        size="large"
                        aria-label="show more"
                        aria-haspopup="true"
                        sx={{ color: gray600 }}
                        onClick={() => { window.open('https://www.facebook.com/app.bhojan', '_blank') }}>
                        <img src={Facebook} height={24} width={24} alt="Facebook" />
                    </IconButton>
                    <IconButton
                        size="large"
                        aria-label="show more"
                        aria-haspopup="true"
                        sx={{ color: gray600 }}
                        onClick={() => { window.open('https://instagram.com/bhojan.app', '_blank') }}>
                        <img src={Instagram} height={24} width={24} alt="Instagram" />
                    </IconButton>
                </Box>
                <IconButton
                    size="large"
                    aria-label="show more"
                    aria-haspopup="true"
                    style={{ backgroundColor: 'transparent', marginLeft: 16, width: 'max-content' }}
                    sx={{ color: gray600 }}
                    onClick={() => { }}>
                    <LocalPhoneOutlined />
                    <Typography
                        variant="h6"
                        noWrap
                        component="div"
                        lineHeight={1.2}
                        sx={{ display: 'flex', marginLeft: '8px', whiteSpace: 'normal', textAlign: 'center' }}>
                        <a href="tel:+91-9000194690" style={{ color: gray600, textDecoration: 'none' }}>+91-9000194690</a>
                    </Typography>
                </IconButton>
                <IconButton
                    size="large"
                    aria-label="show more"
                    aria-haspopup="true"
                    style={{ backgroundColor: 'transparent', marginLeft: 16, width: 'max-content' }}
                    sx={{ color: gray600 }}
                    onClick={() => { }}>
                    <EmailOutlined />
                    <Typography
                        variant="h6"
                        noWrap
                        component="div"
                        lineHeight={1.2}
                        sx={{ display: 'flex', marginLeft: '8px', whiteSpace: 'normal', textAlign: 'center' }}>
                        <a href="mailto:app.bhojan@gmail.com" style={{ color: gray600, textDecoration: 'none' }}>app.bhojan@gmail.com</a>
                    </Typography>
                </IconButton>
            </Box>
        </div>
    );
}

export default WebsiteFooter;