import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';


type AlertDialogProps = {
  open: boolean;
  title: string;
  message: string;
  negativeButton: string;
  positiveButton: string;
  negativeCallback: () => void;
  positiveCallback: () => void;
  positiveButtonStyle?: object;
  negativeButtonStyle?: object;
}

const AlertDialog = (props: AlertDialogProps) => {
  const {
    open, title, message, negativeButton, positiveButton,
    negativeCallback, positiveCallback,
    negativeButtonStyle, positiveButtonStyle
  } = props;

  return (
    <Dialog
      open={open}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">
        {title}
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          {message}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={negativeCallback} style={negativeButtonStyle}>{negativeButton}</Button>
        <Button onClick={positiveCallback} autoFocus style={positiveButtonStyle}>
          {positiveButton}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default AlertDialog;