import Dialog from '@mui/material/Dialog';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import CircularProgress from '@mui/material/CircularProgress';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import { blueIOS } from '../../res/colors';
import Lottie from "lottie-react";
import cookingLoading from "../../images/cooking_loading.json";


type LoadingDialogProps = {
  open: boolean;
  message: string;
}

const LoadingDialog = (props: LoadingDialogProps) => {
  const { open, message } = props;

  return (
    <Dialog
      open={open}
      disableEscapeKeyDown
      onClose={() => { }}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogContent>
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
          <ThemeProvider theme={theme}>
            <div style={{ width: '200px', height: '200px' }}>
              <Lottie animationData={cookingLoading} loop={true} />
            </div>
          </ThemeProvider>
          <DialogContentText id="alert-dialog-description" style={{ marginTop: 16, textAlign: 'center' }}>
            {message}
          </DialogContentText>
        </div>
      </DialogContent>
    </Dialog>
  );
}

const theme = createTheme({
  palette: {
    primary: {
      main: blueIOS,
    }
  },
});

export default LoadingDialog;