import styled from "styled-components";
import { onSnapshot, Unsubscribe, doc, getDoc } from "firebase/firestore";
import { useNavigate } from "react-router-dom";
import BhojanAppbar from "../../components/common/BhojanAppbar";
import { grayBgIOS, redIOS2 } from "../../res/colors";
import { OrderType, RestaurantType } from "../../types/types";
import BillDetails from "./BillDetails";
import OrderInfo from "./OrderInfo";
import PaymentDetails from "./PaymentDetails";
import { useParams } from "react-router-dom";
import OrderItemDetails from "./OrderItemDetails";
import { useEffect, useState } from "react";
import OrderActions from "./OrderActions";
import { getClientInfo, getOrderInfo, isOrderInTerminalState } from "../../utils/utils";
import AlertDialog from "../common/Alert Dialog";
import { deviceOrderId, toastStyle } from "../../utils/constants";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import LoadingDialog from "../common/LoadingDialog";
import { firestore } from "../../firebase";


type OrderDetailProps = {
  restaurant?: RestaurantType;
};

const OrderDetail = ({ restaurant }: OrderDetailProps) => {
  const params = useParams();
  const navigate = useNavigate();
  const [requestLoading, setRequestLoading] = useState(false);
  const [confirmCancelDialog, setConfirmCancelDialogOpen] = useState(false);
  const [orderIdToCancel, setOrderIdToCancel] = useState<string | undefined>();
  var orderListnerUnsubscribe: Unsubscribe | undefined;

  useEffect(() => {
    document.title = restaurant ? `${restaurant.name} order status - Bhojan` : 'Order status - Bhojan';
  }, []);

  const orderMore = (restaurantId: string, tableId: string) => {
    navigate(`/order?rId=${restaurantId}&tId=${tableId}`);
  }

  const cancelOrder = () => {
    if (requestLoading) return;
    setRequestLoading(true);
    setConfirmCancelDialogOpen(false);
    setOrderIdToCancel(undefined);
    const reqBody = {
      updatedBy: getClientInfo(),
    };
    fetch(
      `https://us-central1-bhojan-dev.cloudfunctions.net/cancelOrder?orderId=${orderIdToCancel}`,
      {
        method: "PATCH",
        mode: "cors",
        headers: { 'Content-type': 'application/json', 'Accept': 'application/json' },
        body: JSON.stringify(reqBody)
      }
    )
      .then((response) => response.json())
      .then((data) => {
        setRequestLoading(false);
        if (data.error) {
          toast.error(data.message, toastStyle);
        } else {
          toast.success("Order successfully cancelled", toastStyle);
          console.log("Order cancelled", data);
          localStorage.removeItem(deviceOrderId);
          orderListnerUnsubscribe && orderListnerUnsubscribe();
        }
      })
      .catch((err) => {
        setRequestLoading(false);
        toast.error("Internet connection failed. Couldn't cancel order.", toastStyle);
        console.log("Couldn't place order", err)
      });
  }

  const confirmOrderCancellation = (orderId: string) => {
    setOrderIdToCancel(orderId);
    setConfirmCancelDialogOpen(true);
  }

  const [restaurantDoc, setRestaurantDoc] = useState<RestaurantType | undefined>();
  const getRestaurantData = async (restaurantId: string) => {
    const restaurantDocRef = doc(firestore, "restaurants", restaurantId);
    const restaurantSnap = await getDoc(restaurantDocRef);

    if (restaurantSnap.exists()) {
      const data = restaurantSnap.data() as RestaurantType;
      setRestaurantDoc(data);
    } else {
      // doc.data() will be undefined in this case
      console.log("No such document!");
      setRestaurantDoc(undefined);
    }
  };

  const [order, setOrder] = useState<OrderType>();
  useEffect(() => {
    // Fetch order details
    if (params.id) {
      // Fetch order details
      orderListnerUnsubscribe = onSnapshot(doc(firestore, "orders", params.id), (doc) => {
        if (doc.exists()) {
          const order = doc.data() as OrderType;
          if (isOrderInTerminalState(order.status)) {
            localStorage.removeItem(deviceOrderId);
          } else {
            localStorage.setItem(deviceOrderId, order.orderId);
          }
          setOrder(order);
          if (isOrderInTerminalState(order.status)) {
            orderListnerUnsubscribe && orderListnerUnsubscribe();
          }
          if (!restaurant) {
            getRestaurantData(order.restaurantId);
          }
        } else {
          // doc.data() will be undefined in this case
          console.log("No such document!");
        }
      });
    }
    return () => orderListnerUnsubscribe && orderListnerUnsubscribe()
  }, []);

  const restaurantData = restaurant ? restaurant : restaurantDoc;
  const title = restaurantData ? restaurantData?.name : "Order Detail";
  const subtitle = order ? getOrderInfo(order) : "";

  return (
    <OrderDetailDiv style={{ backgroundColor: grayBgIOS }}>
      <BhojanAppbar title={title} subtitle={subtitle} hideSearch showSearch={() => { }} />
      {(restaurantData && order) ? (
        <div>
          {/* Order Table No, Order status etc. */}
          <OrderInfo tableName={order.tableName} orderStatus={order?.status} />
          {/* Order items list */}
          <OrderItemDetails currency={restaurantData.currency} orderItems={order.orderItems} orderStatus={order.status} />
          {/* Order bill details */}
          <BillDetails currency={restaurantData.currency} totals={order.totals} />
          {/* Payment details */}
          <PaymentDetails {...restaurantData.paymentInfo} />
          <div style={{ height: 160, width: '100%' }} />
          <OrderActions
            order={order}
            orderMore={orderMore}
            cancelOrder={confirmOrderCancellation}
          />
        </div>
      ) : null}
      <AlertDialog
        open={confirmCancelDialog}
        title="Cancel order?"
        message="Are you sure you want to cancel this order?"
        negativeButton="No"
        positiveButton="Yes, Cancel"
        negativeCallback={() => { setConfirmCancelDialogOpen(false) }}
        positiveCallback={cancelOrder}
        positiveButtonStyle={{ color: redIOS2 }}
      />
      <LoadingDialog open={requestLoading} message="Please wait while we cancel your order." />
      <ToastContainer
        position="top-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss={false}
        draggable
        pauseOnHover
        theme="light"
      />
    </OrderDetailDiv>
  );
};

const OrderDetailDiv = styled.div`
  width: 100%;
  min-height: 100vh;
  position: relative;
  max-width: 1920px;
  &.fixed {
    height: 100vh;
    overflow-y: hidden;
  }

  #search-section {
    button circle {
      fill: none;
    }
    main {
      display: block;
    }
    main > div:first-of-type > div:nth-of-type(2) {
      left: 50%;
      transform: translateX(-50%);
    }
  }
`;

export default OrderDetail;
