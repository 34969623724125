import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import { useNavigate } from "react-router-dom"
import { black, blueIOS, gray400, white } from '../../res/colors';
import { Button, CardMedia, Tab, Tabs, Typography } from '@mui/material';
import logo from '../../logo.svg';


const pages = ['Home', 'Features', 'Experience', 'Pricing', 'Contact Us'];

const WebsiteAppbar = () => {

    const navigate = useNavigate();

    const handlePageSelection = (page: string) => {
        switch (page) {
            case pages[0]: navigate("/");
                break;
            case pages[1]: navigate("/features");
                break;
            case pages[2]: navigate("/order?rId=8mhou1&tId=vf24");
                break;
            case pages[3]: navigate("/plans");
                break;
            case pages[4]: navigate("/contact_us");
                break;
        }
    };

    const getButtonColor = (page: string): string => {
        switch (page) {
            case pages[0]: if (window.location.pathname === "/") return blueIOS;
                break;
            case pages[1]: if (window.location.pathname === "/features") return blueIOS;
                break;
            case pages[2]: if (window.location.pathname === "/orders") return blueIOS;
                break;
            case pages[3]: if (window.location.pathname === "/plans") return blueIOS;
                break;
            case pages[4]: if (window.location.pathname === "/contact_us") return blueIOS;
                break;
        }
        return black;
    }

    var tabValue = "Home";
    switch (window.location.pathname) {
        case "/home": tabValue = pages[0];
            break;
        case "/features": tabValue = pages[1];
            break;
        case "/orders": tabValue = pages[2];
            break;
        case "/plans": tabValue = pages[3];
            break;
        case "/contact_us": tabValue = pages[4];
            break;
    }

    return (
        <AppBar position="static" color='default'
            style={{
                backgroundColor: white, boxShadow: 'none', borderBottom: `0.5px solid ${gray400}`
            }}>
            <Toolbar disableGutters style={{ paddingLeft: 16, paddingRight: 16 }}>
                {/* Logo when large screen */}
                <CardMedia
                    component="img"
                    sx={{
                        width: 30,
                        height: 30,
                        display: { xs: 'none', md: 'flex' },
                        mr: 1
                    }}
                    image={logo}
                    alt="Alternate image"
                />

                {/* Title when large screen */}
                <Typography
                    variant="h6"
                    noWrap
                    component="a"
                    href="/"
                    sx={{
                        mr: 2,
                        display: { xs: 'none', md: 'flex' },
                        color: black,
                        textDecoration: 'none',
                    }}
                >
                    Bhojan App
                </Typography>

                {/* Menu when small screen */}
                <Box sx={{ display: { xs: 'flex', md: 'none' }, width: '100%', flex: 1, paddingTop: '8px', flexDirection: 'column' }}>
                    <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
                        {/* Logo when small screen */}
                        <CardMedia
                            component="img"
                            sx={{
                                width: 24,
                                height: 24,
                                display: { xs: 'flex', md: 'none' },
                                mr: 1
                            }}
                            image={logo}
                            alt="Alternate image"
                        />

                        {/* Title when small screen */}
                        <Typography
                            variant="h5"
                            noWrap
                            component="a"
                            href="/"
                            sx={{
                                mr: 2,
                                display: { xs: 'flex', md: 'none' },
                                color: black,
                                textDecoration: 'none',
                                fontWeight: 500
                            }}
                        >
                            Bhojan App
                        </Typography>
                    </div>

                    <Box display="flex" justifyContent="center" width="100%">
                        <Tabs
                            value={tabValue}
                            centered
                            onChange={(_e, tab) => handlePageSelection(tab)}
                            variant="scrollable"
                            scrollButtons="auto"
                            aria-label="Web Menu"
                        >
                            {pages.map((page) => (
                                <Tab key={page} label={page} value={page} />
                            ))}
                        </Tabs>
                    </Box>
                </Box>

                {/* Pages when large screen */}
                <Box sx={{ flexGrow: 1, justifyContent: 'end', display: { xs: 'none', md: 'flex' } }}>
                    {pages.map((page) => (
                        <Button
                            key={page}
                            onClick={() => handlePageSelection(page)}
                            sx={{ my: 2, color: getButtonColor(page), display: 'block' }}
                        >
                            {page}
                        </Button>
                    ))}
                </Box>

            </Toolbar>
        </AppBar>
    );
}

export default WebsiteAppbar;