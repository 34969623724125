import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';
import SearchIcon from '@mui/icons-material/Search';
import { gray400, white } from '../../res/colors';


export type BhojanAppbarProps = {
    title: string;
    subtitle?: string;
    hideSearch: boolean;
    showSearch: () => void;
};

const BhojanAppbar = (data: BhojanAppbarProps) => {
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const [mobileMoreAnchorEl, setMobileMoreAnchorEl] =
        React.useState<null | HTMLElement>(null);

    const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);

    const handleProfileMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleMobileMenuClose = () => {
        setMobileMoreAnchorEl(null);
    };

    const handleMobileMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
        setMobileMoreAnchorEl(event.currentTarget);
    };

    const mobileMenuId = 'primary-search-account-menu-mobile';
    const renderMenu = (
        <Menu
            anchorEl={mobileMoreAnchorEl}
            anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
            }}
            id={mobileMenuId}
            keepMounted
            transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
            }}
            open={isMobileMenuOpen}
            onClose={handleMobileMenuClose}>
            <MenuItem>
                <p>Cancel Order</p>
            </MenuItem>
            <MenuItem onClick={handleProfileMenuOpen}>
                <p>Report Restaurant</p>
            </MenuItem>
        </Menu>
    );

    return (
        <Box sx={{ flexGrow: 1 }}>
            <AppBar position="static" color='default' style={{ backgroundColor: white, boxShadow: 'none', borderBottom: `0.5px solid ${gray400}` }}>
                <Toolbar style={{ paddingLeft: 16, paddingRight: 16, paddingTop: 8, paddingBottom: 8 }}>
                    <Typography
                        style={{ flex: 1 }}
                        variant="h6"
                        noWrap
                        component="div"
                        lineHeight={1.2}>
                        {data.title}
                        <Typography
                            variant="body1"
                            noWrap
                            component="div"
                            lineHeight={1.2}
                            sx={{ display: 'block', color: "text.secondary" }}>
                            {data.subtitle}
                        </Typography>
                    </Typography>

                    {data.hideSearch ? null : (<Box sx={{ display: 'flex' }}>
                        <IconButton
                            size="large"
                            aria-label="show more"
                            aria-controls={mobileMenuId}
                            aria-haspopup="true"
                            color="inherit"
                            onClick={data.showSearch}>
                            <SearchIcon />
                        </IconButton>
                    </Box>)}
                    {/* <Box sx={{ display: 'flex' }}>
                        <IconButton
                            size="large"
                            aria-label="show more"
                            aria-controls={mobileMenuId}
                            aria-haspopup="true"
                            onClick={handleMobileMenuOpen}
                            color="inherit">
                            <MoreIcon />
                        </IconButton>
                    </Box> */}
                </Toolbar>
            </AppBar>
            {/* {renderMenu} */}
        </Box>
    );
}

export default BhojanAppbar;