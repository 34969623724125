import { Card, Divider, Typography } from "@mui/material";
import { black, gray600 } from "../../res/colors";
import { OrderedItemType } from "../../types/types";
import { OrderStatusEnum } from "../../utils/constants";
import { getTotalOrderItems } from "../../utils/utils";
import OrderItem from "./OrderItem";


export type OrderItemDetailsTypes = {
  orderItems: OrderedItemType[];
  currency: string;
  orderStatus: OrderStatusEnum;
};

const OrderItemDetails = (orderItemDetails: OrderItemDetailsTypes) => {
  return (
    <Card
      id="item-details"
      variant="outlined"
      style={{
        margin: 16, paddingLeft: 16, paddingTop: 8, paddingBottom: 16, paddingRight: 16,
        border: "none"
      }}>
      <Typography
        style={{ color: black, fontWeight: 500 }}
        variant="subtitle1"
      >
        Item Details ({getTotalOrderItems(orderItemDetails.orderItems)})
      </Typography>
      <Typography
        style={{
          fontSize: 14, lineHeight: 1, color: gray600
        }} variant="body1">
        Item price maybe inclusive of taxes
      </Typography>
      <Divider style={{ marginTop: 8 }} />
      {orderItemDetails.orderItems.map((item: OrderedItemType) => (
        <OrderItem
          key={item.orderItemId}
          orderedItem={item}
          currency={orderItemDetails.currency}
          orderStatus={orderItemDetails.orderStatus}
        />
      ))}
    </Card>
  );
};

export default OrderItemDetails;
