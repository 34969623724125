import { DeviceUUID } from "device-uuid";
import { MuiTelInputCountry } from 'mui-tel-input';
import { AddOnType, OrderedItemType, SelectedItemType, OrderType, FoodItemType, AddOnSelection, AddOnOptionType, OrderTotalsType, RestaurantType, FoodUnavailableInfoType, ServingInfoType, TimeRange, TaxTotalInfoType, TaxType, PlanExpiryDetails } from "../types/types";
import { AddOnTypeEnum, AlertType, defaultCurrency, FoodTypeEnum, OrderStatusEnum, RestaurantPlanEnum, TaxApplication, UnavailableReasonEnum } from "./constants";

export function formatAmount(
    amount: number, locale: string, currency: string, forceTwoDecimals: boolean = false
): string {
    if (!currency) {
        currency = defaultCurrency
    }
    try {
        const currencyFormatter = new Intl.NumberFormat(locale, {
            style: 'currency',
            currency: currency,
            maximumFractionDigits: (amount % 1 != 0 || forceTwoDecimals) ? 2 : 0
        });
        return currencyFormatter.format(amount);
    } catch (ex) {
        const currencyFormatter = new Intl.NumberFormat(locale, {
            style: 'currency',
            currency: defaultCurrency,
            maximumFractionDigits: (amount % 1 != 0 || forceTwoDecimals) ? 2 : 0
        });
        return currencyFormatter.format(amount);
    }
}

export function isCustomisable(foodItem: FoodItemType): boolean {
    if (foodItem.addOns) {
        return (foodItem.addOns.length > 0)
    }
    return false;
}

export function getTotalOrderItems(
    orderItems: SelectedItemType[] | OrderedItemType[]
): number {
    var itemCount = 0;
    orderItems.forEach((orderItem) => { itemCount = itemCount + orderItem.quantity });
    return itemCount;
}

export function getOrderItemTotal(
    orderItems: SelectedItemType[] | OrderedItemType[]
): number {
    let orderItemTotal: number = 0;
    orderItems.forEach((orderItem) => {
        orderItemTotal += orderItem.quantity * (orderItem.foodData.price ? orderItem.foodData.price : 0);
        const selectedAddOnOptions = orderItem.addOns?.flatMap(addOn => addOn.addOnOptionIds);
        const allAddOnOptions = orderItem.foodData.addOns?.flatMap(addOn => addOn.addOnOptions);
        allAddOnOptions?.forEach((option) => {
            if (selectedAddOnOptions && selectedAddOnOptions?.indexOf(option.addOnOptionId) != -1) {
                orderItemTotal += option.price;
            }
        });
    });
    return orderItemTotal;
}

export const getOrderTotals = (
    orderItems: SelectedItemType[] | OrderedItemType[], restaurant: RestaurantType
): OrderTotalsType => {
    var itemTotal = 0;
    var grandTotal = 0;
    let taxesIncludedInPricePerc = 0;
    let taxes: TaxTotalInfoType[] = [];

    restaurant.taxes?.forEach((tax: TaxType) => {
        if (tax.taxApplication === TaxApplication.INCLUDED_IN_PRICE) {
            taxesIncludedInPricePerc += tax.taxPercentage;
            taxes.push({
                taxId: tax.taxId,
                taxName: tax.taxName,
                taxPercentage: tax.taxPercentage,
                taxApplication: tax.taxApplication,
                taxTotal: 0
            })
        }
    });

    orderItems.forEach((orderItem) => {
        let orderItemPriceGross = orderItem.foodData.price;
        const selectedAddOnOptions = orderItem.addOns?.flatMap(addOn => addOn.addOnOptionIds);
        const allAddOnOptions = orderItem.foodData.addOns?.flatMap(addOn => addOn.addOnOptions);

        allAddOnOptions?.forEach((option) => {
            if (selectedAddOnOptions && selectedAddOnOptions?.indexOf(option.addOnOptionId) != -1) {
                orderItemPriceGross += option.price;
            }
        });

        orderItem.price = (orderItemPriceGross * 100 / (100 + taxesIncludedInPricePerc));
        orderItem.orderItemTotal = orderItem.price * orderItem.quantity;
        itemTotal += orderItem.orderItemTotal;
    });

    grandTotal += itemTotal;

    // Add taxTotal for price inclusive taxes in taxes array
    // Till now only price inclusive taxes are added to the array
    taxes.forEach(tax => {
        tax.taxTotal = (itemTotal * tax.taxPercentage / 100);
        grandTotal += tax.taxTotal;
    });

    // Tax and other charges
    restaurant.taxes?.forEach((tax: TaxType) => {
        if (tax.taxApplication === TaxApplication.APPLY_ON_TOTAL) {
            const particularTaxTotal = (itemTotal * tax.taxPercentage / 100);
            grandTotal += particularTaxTotal;
            taxes.push({
                taxId: tax.taxId,
                taxName: tax.taxName,
                taxPercentage: tax.taxPercentage,
                taxApplication: tax.taxApplication,
                taxTotal: particularTaxTotal
            })
        }
    });

    return { itemTotal, taxes, grandTotal };
}

export function isOrderInTerminalState(status: OrderStatusEnum): boolean {
    return status === OrderStatusEnum.CANCELLED || status === OrderStatusEnum.COMPLETED
}

export function getOrderStatusLabel(status: OrderStatusEnum): string {
    switch (status) {
        case OrderStatusEnum.PENDING:
            return "Pending"
        case OrderStatusEnum.IN_PROCESS:
            return "Preparing Food"
        case OrderStatusEnum.COMPLETED:
            return "Completed"
        case OrderStatusEnum.CANCELLED:
            return "Cancelled"
        case OrderStatusEnum.PARTIALLY_PROCESSED:
            return "Partially Prepared"
    }
}

export const formatDateTimeShort = (createdAt: any): string => {
    return Intl.DateTimeFormat('en-US', {
        month: 'short', day: "numeric",
        hour: "2-digit", minute: "2-digit",
        hour12: true,
    }).format(createdAt.toDate());
}

export const getOrderInfo = (order: OrderType): string => {
    return `Order#${order.readableOrderId} | ${formatDateTimeShort(order.createdAt)}`;
}

export const getAddOnType = (addOn: AddOnType): AddOnTypeEnum => {
    if (addOn.mandatory) {
        if (addOn.multiChoice) {
            return AddOnTypeEnum.MANDATORY_MULTI
        } else {
            return AddOnTypeEnum.MANDATORY_SINGLE
        }
    } else {
        if (addOn.multiChoice) {
            return AddOnTypeEnum.OPTIONAL_MULTI
        } else {
            return AddOnTypeEnum.OPTIONAL_SINGLE
        }
    }
}

export const getFoodTypeWithAddOns = (
    foodData: FoodItemType, addOns?: AddOnSelection[]
): FoodTypeEnum => {
    var foodType = foodData.foodType;
    const selectedAddOnOptions = addOns?.flatMap(addOn => addOn.addOnOptionIds);
    const allAddOnOptions = foodData.addOns?.flatMap(addOn => addOn.addOnOptions);
    allAddOnOptions?.forEach((option) => {
        if (selectedAddOnOptions && selectedAddOnOptions?.indexOf(option.addOnOptionId) != -1) {
            if (option.foodType == FoodTypeEnum.NON_VEG && (foodType == FoodTypeEnum.VEG || foodType == FoodTypeEnum.NON_VEG)) {
                foodType = FoodTypeEnum.NON_VEG;
            } else if (option.foodType == FoodTypeEnum.EGG && foodType == FoodTypeEnum.VEG) {
                foodType = FoodTypeEnum.EGG;
            }
        }
    });
    return foodType;
}

export const findSameAddOnConfigItem = (
    orderItems: SelectedItemType[],
    foodItem: FoodItemType,
    addOnsSelections: AddOnSelection[]
): SelectedItemType | undefined => {
    var foundItem: SelectedItemType | undefined = undefined;
    orderItems.forEach(orderItem => {
        if (orderItem.foodData.foodId == foodItem.foodId) {
            // Check if the same addon config is already selected
            if ((orderItem.addOns?.length == addOnsSelections.length) && isAddOnsSelectionSame(orderItem.addOns, addOnsSelections)) {
                foundItem = orderItem;
            }
        }
    });
    return foundItem;
}

export const findSameAddOnConfigItems = (
    orderItems: SelectedItemType[],
    addOnsSelections: AddOnSelection[]
): SelectedItemType[] => {
    const foundItems: SelectedItemType[] = [];
    orderItems.forEach(orderItem => {
        // Check if the same addon config is already selected
        if ((orderItem.addOns?.length == addOnsSelections.length) && isAddOnsSelectionSame(orderItem.addOns, addOnsSelections)) {
            foundItems.push(orderItem);
        }
    });
    return foundItems;
}

export const isAddOnsSelectionSame = (
    first?: AddOnSelection[], second?: AddOnSelection[]
): boolean => {
    if (first && second) {
        const firstOptionsIds = first.flatMap(item => item.addOnOptionIds);
        const secondOptionsIds = second.flatMap(item => item.addOnOptionIds);
        const diff = firstOptionsIds.filter(item => secondOptionsIds.indexOf(item) < 0);
        return (firstOptionsIds.length == secondOptionsIds.length) && diff.length == 0;
    }
    return false;
}

export const getTotalItemPrice = (
    basePrice: number, selectedAddOns?: AddOnSelection[], addOnOptions?: AddOnOptionType[]
): number => {
    var total = basePrice;
    selectedAddOns?.flatMap(addOn => addOn.addOnOptionIds).forEach((selectedOptionId) => {
        const selectedOption = addOnOptions?.find(option => option.addOnOptionId == selectedOptionId)
        if (selectedOption) {
            total += selectedOption.price;
        }
    });
    return total;
}

export const getAddOnsInfo = (
    selectedAddOns?: AddOnSelection[], addOnsData?: AddOnType[]
): string => {
    var info = "";
    selectedAddOns?.flatMap(addOn => addOn.addOnOptionIds).forEach((selectedOptionId) => {
        const selectedOption = addOnsData?.flatMap(item => item.addOnOptions).find(option => option.addOnOptionId == selectedOptionId)
        if (selectedOption) {
            if (info.length == 0) {
                info += selectedOption.name;
            } else {
                info += `, ${selectedOption.name}`;
            }
        }
    });
    return info;
}

/**
 * Remove addOns that has empty addOnOptionIds
 * @param addOnsSelections 
 */
export const cleanAddOnSelections = (addOnsSelections: AddOnSelection[]): AddOnSelection[] => {
    return addOnsSelections.filter(addOn => addOn.addOnOptionIds.length > 0)
}

export const getClientInfo = (): object => {
    var deviceId = new DeviceUUID().get();
    const deviceData = new DeviceUUID().parse();
    return {
        deviceId: deviceId,
        platform: deviceData.platform,
        os: deviceData.os,
        browser: deviceData.browser,
        version: deviceData.version,
        language: deviceData.language
    }
}

/**
 * Removes unavailable addon from the food items.
 */
export const removeUnavailableAddOns = (restaurant: RestaurantType): RestaurantType => {
    restaurant.foodItems.forEach(foodItem => {
        foodItem.addOns = foodItem.addOns?.filter(addOn => addOn.availability);
    });
    return restaurant;
}

export const formatAMPM = (date: Date) => {
    var hours = date.getHours();
    const minutes = date.getMinutes();
    const ampm = hours >= 12 ? 'PM' : 'AM';
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'
    const minutesString = minutes < 10 ? '0' + minutes : minutes;
    return hours + ':' + minutesString + ampm;
}

export const getFoodUnavailableInfo = (
    availability: boolean, servingInfo?: ServingInfoType
): FoodUnavailableInfoType | undefined => {
    if (!availability) {
        return { reason: UnavailableReasonEnum.JUST_UNAVAILABLE, message: "Currently Unavailable" }
    } else if (servingInfo && servingInfo?.allDay == false) {
        // Get current device time and check if it's between serving time.
        const currentDate = new Date();
        const startTime = servingInfo.startTime; // 17:45
        const endTime = servingInfo.endTime; // 19:45

        const startDate = new Date();
        startDate.setHours(Number(startTime.split(":")[0]));
        startDate.setMinutes(Number(startTime.split(":")[1]));
        startDate.setSeconds(0);

        const endDate = new Date();
        endDate.setHours(Number(endTime.split(":")[0]));
        endDate.setMinutes(Number(endTime.split(":")[1]));
        endDate.setSeconds(0);

        const startTimeAMPM = formatAMPM(startDate);
        const endTimeAMPM = formatAMPM(endDate);

        if (startDate < currentDate && endDate > currentDate) {
            return undefined
        } else {
            return { reason: UnavailableReasonEnum.SERVING_TIME, message: `Available Between ${startTimeAMPM} - ${endTimeAMPM}` }
        }
    }
    return undefined;
}

/**
 * Performs check on opening hours with the current system time.
 * @param restaurant 
 * @returns if current time is outside restaurant opening hours
 */
export const isRestaurantOpenFn = (restaurant: RestaurantType): boolean => {
    if (restaurant.closeForOrder === true || restaurant.planInfo?.plan === RestaurantPlanEnum.LITE) {
        return false;
    }
    var isOpen = true;
    const currentDate = new Date();

    var timeRanges: TimeRange[] | undefined;
    switch (currentDate.getDay()) {
        case 0:
            if (restaurant.openingHours?.sunday?.open == false) return false;
            timeRanges = restaurant.openingHours?.sunday?.timeRange
            break;

        case 1:
            if (restaurant.openingHours?.monday?.open == false) return false;
            timeRanges = restaurant.openingHours?.monday?.timeRange
            break;

        case 2:
            if (restaurant.openingHours?.tuesday?.open == false) return false;
            timeRanges = restaurant.openingHours?.tuesday?.timeRange
            break;

        case 3:
            if (restaurant.openingHours?.wednesday?.open == false) return false;
            timeRanges = restaurant.openingHours?.wednesday?.timeRange
            break;

        case 4:
            if (restaurant.openingHours?.thursday?.open == false) return false;
            timeRanges = restaurant.openingHours?.thursday?.timeRange
            break;

        case 5:
            if (restaurant.openingHours?.friday?.open == false) return false;
            timeRanges = restaurant.openingHours?.friday?.timeRange
            break;

        case 6:
            if (restaurant.openingHours?.saturday?.open == false) return false;
            timeRanges = restaurant.openingHours?.saturday?.timeRange
            break;

        default:
            break;
    }

    // Check if current time is between any of the time range.
    if (timeRanges && timeRanges.length > 0) {
        for (let timeRange of timeRanges) {
            const startTime = timeRange.startTime; // 17:45
            const endTime = timeRange.endTime; // 19:45

            const startDate = new Date();
            startDate.setHours(Number(startTime.split(":")[0]));
            startDate.setMinutes(Number(startTime.split(":")[1]));
            startDate.setSeconds(0);

            const endDate = new Date();
            endDate.setHours(Number(endTime.split(":")[0]));
            endDate.setMinutes(Number(endTime.split(":")[1]));
            endDate.setSeconds(0);

            if (currentDate > startDate && currentDate < endDate) {
                isOpen = true
                break;
            } else {
                isOpen = false
            }
        }
    } else {
        return isOpen;
    }
    return isOpen;
}

export const getCountryCode = (): MuiTelInputCountry => {
    var locale;
    if (window.navigator.languages) {
        locale = window.navigator.languages[0];
    } else {
        locale = window.navigator.language;
    }
    var countryCode = locale && locale.split("-")[1]
    return (countryCode ? countryCode : "IN") as MuiTelInputCountry;
}

export const getPlanExpiryDetails = (restaurant: RestaurantType): PlanExpiryDetails => {
    const planExpiryDetails: PlanExpiryDetails = {
        isPlanExpired: false,
        isPlanExpiringSoon: false,
        alertMessage: undefined,
        alertType: AlertType.INFO,
        planInfo: restaurant.planInfo
    };
    const validity = restaurant.planInfo?.validTill
    if (validity) {
        const days7InFuture = new Date().setDate(new Date().getDate() + 7)
        
        // Expiry case
        if (validity.toDate() < new Date()) {
            const days5InPast = new Date().setDate(new Date().getDate() - 5)
            const isMoreThan5Days = (validity.toDate().getTime() < days5InPast)

            const days10InPast = new Date().setDate(new Date().getDate() - 10)
            const isMoreThan10Days = (validity.toDate().getTime() < days10InPast)

            const expiredAlertMessage = "Your plan has expired. Please contact support team for resolution."
            switch (restaurant.planInfo?.plan) {
                // Pro and Plus plan gets 10 days grace period
                case RestaurantPlanEnum.PRO:
                case RestaurantPlanEnum.PLUS:
                    planExpiryDetails.alertMessage = expiredAlertMessage
                    if (isMoreThan10Days) {
                        planExpiryDetails.alertType = AlertType.CRITICAL
                    } else {
                        planExpiryDetails.alertType = AlertType.WARNING
                    }
                    break;
                // Lite plan gets 5 dayys grace period
                case RestaurantPlanEnum.LITE:
                    planExpiryDetails.alertMessage = expiredAlertMessage
                    if (isMoreThan5Days) {
                        planExpiryDetails.alertType = AlertType.CRITICAL
                    } else {
                        planExpiryDetails.alertType = AlertType.WARNING
                    }
                    break;
                case RestaurantPlanEnum.FREE:
                case RestaurantPlanEnum.TRIAL:
                    planExpiryDetails.alertMessage = expiredAlertMessage
                    planExpiryDetails.alertType = AlertType.CRITICAL
                    break;
            }
        } else if (validity.toDate() < days7InFuture) {
            // Expiring soon, expiring in next 7 days
            const expiringAlertMessage = "Your plan will expire soon. Please contact support team for resolution."
            planExpiryDetails.alertMessage = expiringAlertMessage
            planExpiryDetails.alertType = AlertType.WARNING
        }
    }

    return planExpiryDetails;
}