import { black } from "../../res/colors";
import 'react-toastify/dist/ReactToastify.css';
import WebsiteAppbar from "./WebsiteAppbar";
import { useEffect } from "react";
import { CardMedia, Grid, Typography } from "@mui/material";
import timer from '../images/timer.svg';
import unique from '../images/unique.svg';
import digital from '../images/digital.svg';
import qrCode from '../images/qr.svg';
import smile from '../images/smile.svg';
import setting from '../images/setting.svg';
import payment from '../images/payment.svg';
import staff from '../images/staff.svg';
import { WebDivWrapper } from "./Home";
import WebsiteFooter from "./WebsiteFooter";


const Features = () => {

  const featureContainer = (logo: any, title: string, message: string) => {
    return (
      <div style={{
        display: 'flex', flex: 1, margin: 12, borderRadius: 8,
        padding: 12, alignItems: 'center', flexDirection: 'column',
        background: '#FCF3DA'
      }}>
        <CardMedia
          component="img"
          sx={{
            width: 30,
            height: 30
          }}
          image={logo}
          alt="Alternate image"
        />
        <div style={{ flex: 1 }} />
        <div style={{}}>
          <Typography
            variant="h6"
            noWrap
            component="div"
            lineHeight={1.2}
            sx={{ display: 'flex', color: black, marginTop: '8px', whiteSpace: 'normal', textAlign: 'center', justifyContent: 'center' }}>
            {title}
          </Typography>
          <Typography
            variant="subtitle1"
            noWrap
            component="div"
            lineHeight={1.2}
            sx={{ fontWeight: 400, display: 'flex', color: black, whiteSpace: 'normal', textAlign: 'center', justifyContent: 'center', marginTop: '4px' }}>
            {message}
          </Typography>
        </div>
      </div>
    );
  }

  const realTimePricing = featureContainer(
    timer, "Real-Time price changes",
    "Helps you earn more when the demand is high \n Set different prices for weekends"
  )

  const digitalMenu = featureContainer(
    digital, "Digital Menu",
    "Easily update your menu \n Try different arrangements"
  )

  const scanToOrder = featureContainer(
    qrCode, "Scan QR to Order",
    "Share QR codes with customers to receive orders"
  )

  const tableQR = featureContainer(
    unique, "Unique QR for each Table",
    "Easily know from which tables orders is received"
  )

  const simpleUx = featureContainer(
    smile, "Simple & Familiar UX",
    "Give your customers familiar experience as existing online ordering apps"
  )

  const foodCustomization = featureContainer(
    setting, "Food customization",
    "Add Ons on any food item is fully supported"
  )

  const directPaytment = featureContainer(
    payment, "Direct Payment",
    "Get paid directly by your customers \n We don’t hold your earnings"
  )

  const staffManagement = featureContainer(
    staff, "Staff Management",
    "Add Chef to the staff to send order related notifications \n Fully control what your staff can do"
  )

  useEffect(() => {
    document.title = "Bhojan - Features";
  }, []);

  return (
    <WebDivWrapper>
      <div style={{ position: 'sticky', top: 0, zIndex: 200 }}>
        <WebsiteAppbar />
      </div>
      <Grid container spacing={2} >
        <Grid item xs={6}>
          {realTimePricing}
        </Grid>
        <Grid item xs={6}>
          {digitalMenu}
        </Grid>
      </Grid>
      <Grid container spacing={2} >
        <Grid item xs={6}>
          {scanToOrder}
        </Grid>
        <Grid item xs={6}>
          {tableQR}
        </Grid>
      </Grid>
      <Grid container spacing={2} >
        <Grid item xs={6}>
          {simpleUx}
        </Grid>
        <Grid item xs={6}>
          {foodCustomization}
        </Grid>
      </Grid>
      <Grid container spacing={2} >
        <Grid item xs={6}>
          {directPaytment}
        </Grid>
        <Grid item xs={6}>
          {staffManagement}
        </Grid>
      </Grid>
      <WebsiteFooter />
      <div style={{ height: 50 }} />
    </WebDivWrapper>
  );
};

export default Features;
