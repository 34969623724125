import { ToastOptions } from "react-toastify";

export enum FoodTypeEnum {
    VEG = "VEG",
    NON_VEG = "NON_VEG",
    EGG = "EGG"
}

export enum OrderStatusEnum {
    PENDING = "PENDING",
    IN_PROCESS = "IN_PROCESS",
    COMPLETED = "COMPLETED",
    CANCELLED = "CANCELLED",
    PARTIALLY_PROCESSED = "PARTIALLY_PROCESSED"
}

export enum WalletTypeEnum {
    GPAY = "GPAY",
    PAYTM = "PAYTM",
    UPI = "UPI"
}

export enum AddOnTypeEnum {
    OPTIONAL_SINGLE = "OPTIONAL_SINGLE",
    OPTIONAL_MULTI = "OPTIONAL_MULTI",
    MANDATORY_SINGLE = "MANDATORY_SINGLE",
    MANDATORY_MULTI = "MANDATORY_MULTI"
}

export const defaultCurrency = 'INR';

export const deviceOrderId = 'device_order_id';

export const toastStyle: ToastOptions = {
    position: "top-center",
    autoClose: 3000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    theme: "dark",
}

export enum UnavailableReasonEnum {
    JUST_UNAVAILABLE = "JUST_UNAVAILABLE",
    SERVING_TIME = "SERVING_TIME"
}

export enum TaxApplication {
    INCLUDED_IN_PRICE = "INCLUDED_IN_PRICE",
    APPLY_ON_TOTAL = "APPLY_ON_TOTAL"
}

export enum RestaurantPlanEnum {
    FREE = "FREE",
    PLUS = "PLUS",
    PRO = "PRO",
    LITE = "LITE",
    TRIAL = "TRIAL",
}

export enum RestaurantStatusEnum {
    ACTIVE = "ACTIVE",
    SUSPENDED = "SUSPENDED",
    BLOCKED = "BLOCKED"
}

export enum AlertType {
    INFO = "INFO",
    WARNING = "WARNING",
    CRITICAL = "CRITICAL"
}